@import '../colors.scss';
@import '../mixins.scss';

header {
  
  .navbar {
    padding-top: .75rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .navbar-brand {
      position: relative;
      z-index: 1000;
      padding-left: 1rem;
      padding-right: 1rem;


      a.logo {
        font-size: 1rem;
        font-weight: 500;
        color: $SURF_GRAY;

        svg {
          vertical-align: middle;
          transform: translateY(-1px);
          margin-left: 5px;
          margin-right: 12px;
        }
      }
    }
    .btn {
      border-radius: 20px;
      padding: 0.4rem 1.25rem;
      position: relative;
      z-index: 20;
      
    }
    .navbar-collapse {
      flex-grow: 1;
      margin-left: 1rem;
      margin-right: 1rem;

      @media (max-width: 575px) {
        padding-top: 0.5rem;
      }

      .navbar-nav {
        flex-grow: 1;
        @include transition(0.15s);
        box-shadow: 0px 0px 0px 0 rgba(33,39,56,0.01);

        & > * {
          margin-left: 15px;

          @media (max-width: 575px) {
            margin-left: 0;
          }
        }
        .nav-item {
          a {
            &.nav-link {
              color: $SURF_GRAY;
              font-weight: 500;


              sup {
                color: $SURF_GREEN;
                color: #2FA851;
              }
              
              &:hover, &.active {
                color: $SURF_BLUE;

                sup {
                  color: $SURF_BLUE;
                }
              }

              @media (max-width: 575px) {
                padding: 1rem 0.25rem;
                position: relative;

                &:after {
                  position: absolute;
                  top: 1.25rem;
                  right: 0.5rem;
                }
              }
            }
          }
          &:not(.show) {
            a {
              &.nav-link {
                @media (max-width: 575px) {
                  border-bottom: 1px solid rgba($SURF_TERTIARY, 0.2);
                }
              }
            }
          }
        }
      }
      a {
        &.nav-link {
          color: $SURF_GRAY;
          font-weight: 500;

          &:hover, &.active {
            color: $SURF_BLUE;
          }
        }
        &.btn-icon {
          padding-right: 1rem;

          @media (max-width: 575px) {
            padding: 1rem 0.25rem;
            position: relative;
          }
        }
      }
    }
    .navbar-toggler {
      border: none;
      position: relative;
      width: 54px;
      height: 40px;
      z-index: 2;
      margin-right: 0.5rem;

      &, &:hover, &:focus, &:active {
        &, &:hover, &:focus, &:active {
          border: none;
          box-shadow: none;
          outline: none;
        }
      } 

      .navbar-toggler-icon {
        background-image: none;
        display: none;
      }

      &:before, &:after {
        content: '';
        height: 2px;
        width: 24px;
        background-color: $SURF_GRAY;
        position: absolute;
        @include transition(0.15s);
        left: 15px;
      }
      &:before {
        top: 15px;
      }
      &:after {
        top: 21px;
      }

      &:not(.collapsed) {
        &:before {
          transform: rotate(45deg) translateY(2px) translateX(2px);
        }
        &:after {
          transform: rotate(-45deg) translateY(-2px) translateX(2px);
        }
      }

      &:hover {
        &:before, &:after {
          background-color: $SURF_BLUE;
          @include transition(0.35s);

        }
      }
    }
  }
}