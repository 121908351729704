@import '../colors.scss';
@import '../mixins.scss';

//
// Base styles
//

.accordion {
  margin-left1: -2rem;
  margin-right1: -2rem;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 1.5rem 2rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: $SURF_GRAY;
  text-align: left; // Reset button style
  background-color: transparent;
  border: 0;
  overflow-anchor: none;
  @include transition(0.5s);

  @media (max-width: 767px) {
    padding: 1.5rem 1rem;
  }

  &:not(.collapsed) {
    color: $SURF_GRAY;
    background-color: transparent;
    border-radius: 20px 20px 0 0;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    @include transition(0.15s);
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .05);
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: transparent;
  border: none;
  @include transition(0.15s);

  .accordion-header {
    position: relative;

    &:before {
      position: absolute;
      top: 0px;
      height: 1px;
      left: 2rem;
      right: 2rem;
      background-color: #E2E3E4;
      content: '';

      @media (max-width: 767px) {
        left: 1rem;
        right: 1rem;
      }
    }
  }
  &:first-of-type {
    .accordion-header:before {
      content: none;
    }
  }

  &.active {
    border-radius: 20px;
    color: $SURF_GRAY;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 125px -10px rgba(0, 0, 0, 0.05);
    @include transition(0.35s);

    .accordion-button {
      outline: none;
      box-shadow: none;
    }

    .accordion-header {
      position: relative;

      &:after {
        position: absolute;
        bottom: 0px;
        height: 1px;
        left: 32px;
        right: 32px;
        background-color: #E2E3E4;
        content: '';
      }
      &:before {
        content: none;
      }
    }
  }

  &.active ~ & {
    .accordion-header {
      &:before {
        content: none;
      }
    }
  }
  &.active ~ & ~ & {
    .accordion-header {
      &:before {
        content: '';
      }
    }
  }

  .accordion-button {
    &.collapsed {
      border-radius: 20px;
    }
  }

  .accordion-collapse {
  }
}

.accordion-body {
  padding: 2rem;

  @media (max-width: 767px) {
    padding: 1rem;
  }
}


// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-right: 0;
    border-left: 0;

    &:first-child { border-top: 0; }
    &:last-child { border-bottom: 0; }

    .accordion-button {
    }
  }
}
