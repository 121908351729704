@import './colors.scss';
@import './mixins.scss';

@import './components/buttons.scss';
@import './components/forms.scss';
@import './components/accordion.scss';
@import './components/typography.scss';


html {
  font-size: 16px;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  letter-spacing: -.01em;
  background-color: $SURF_GRAY_BACKGROUND;
}

a {
  @include transition(0.15s);

  &, &:focus, &:active, &:hover {
    &, &:focus, &:active, &:hover {
      text-decoration: none;
      outline: none;
    }
  }
  &:focus, &:active, &:hover {
    @include transition(0.35s);
  }
  &.active {
    color: $SURF_BLUE;
  }
}

html, body, #root, .ws-app {
  /* IE 10-11 didn't like using min-height */
  height: 100%;
}
.ws-app {
  display: flex;
  flex-direction: column;
}

.container-fluid {
  padding-left: 16px;
  padding-right: 16px;
}
div[class^="container"], div[class*=" container-"] {

  @media (max-width: 575px) {
    padding-left1: 20px;
    padding-right1: 20px;
  }
}

header {
  flex-shrink: 0;
}
main {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 2.4rem;
  letter-spacing: -0.03em;

  small {
    font-size: 30%;
  }
}

.pulse main {
  padding-top: 85px;
  padding-bottom: 90px;

  .page-description {
    font-size: 1.2rem;
    margin-top: 16px;
    margin-bottom: 80px;
  }
  .pulse-overview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.pulse-overview-tracked {
  hr {
    background: none;
    border: none;
    border-bottom: 1px solid #F4F4F5;
    margin-bottom: 50px;
  }
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z' fill='%23FF7183'/%3E%3Cpath d='M3 6L7.5 10.5L16.5 1.5' stroke='%231F262A' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z' fill='%23FF7183'/%3E%3Cpath d='M3 6L7.5 10.5L16.5 1.5' stroke='%231F262A' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 0 2px;
      text-indent: 25px;
      max-width: 200px;
      padding-left: 0;
      list-style-position: inside;
      color: #707376;
      font-size: 0.875rem;
    }

    &.standalone {
      margin-top: 85px;

      @media (max-width: 767px) {
        margin-top: 0px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;

        li {
          width: 50%;
          flex-grow: 1;
          flex-shrink: 1;
          max-width: 100%;
          padding-right: 20%;

          &:last-child {
            transform: translateX(16px);
          }
        }
      }
    }
  }
}

.flex-grow {
  flex-grow: 1;
  flex-shrink: 1;
}
.flex-shrink {
  flex-grow: 0;
  flex-shrink: 0;
}

.card {
  background-color: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 2rem;
  box-shadow: 0px 1px 125px -30px rgba(0, 0, 0, 0.05);

  &.card-transparent {
    background-color: transparent;
    box-shadow: none;
  }
}

.content-container {
  ul, ol {
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    li {
      position: relative;
      padding-left: 2rem;
      list-style-type: none;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 10px;
        background-color: #bec4c8;
        color: #bec4c8;
        left: 0;
        top: 13px;
      }
    }
  }
  ul ol {
    li:nth-child(1):before {content: 'a)';}
    li:nth-child(2):before {content: 'b)';}
    li:nth-child(3):before {content: 'c)';}
    li:nth-child(4):before {content: 'd)';}
    li:nth-child(5):before {content: 'e)';}
    li:nth-child(6):before {content: 'f)';}
    li:nth-child(7):before {content: 'g)';}
    li:nth-child(8):before {content: 'h)';}
    li:nth-child(9):before {content: 'i)';}
    li:nth-child(10):before {content: 'j)';}
    li:before {
      position: absolute;
      background-color: transparent;
      color: #bec4c8;
      left: 0;
      top: auto;
    }
  }
  ol {
    li:nth-child(1):before {content: '1.';}
    li:nth-child(2):before {content: '2.';}
    li:nth-child(3):before {content: '3.';}
    li:nth-child(4):before {content: '4.';}
    li:nth-child(5):before {content: '5.';}
    li:nth-child(6):before {content: '6.';}
    li:nth-child(7):before {content: '7.';}
    li:nth-child(8):before {content: '8.';}
    li:nth-child(9):before {content: '9.';}
    li:nth-child(10):before {content: '10.';}
    li:before {
      position: absolute;
      background-color: transparent;
      color: #bec4c8;
      left: 0;
      top: auto;
    }
  }
}

b {
  font-weight: 600;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .loader-spinner {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $SURF_GRAY_2;
  color: $SURF_GRAY_2;
  -webkit-animation: dot-flashing 1s infinite linear alternate;
  animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.loader-spinner::before, .loader-spinner::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.loader-spinner::before {
  left: 30px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $SURF_GRAY_2;
  color: $SURF_GRAY_2;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loader-spinner::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $SURF_GRAY_2;
  color: $SURF_GRAY_2;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: $SURF_GRAY_2;
  }
  50%,
  100% {
    background-color: lighten($SURF_GRAY_2, 40);
  }
}

@keyframes dot-flashing {
  0% {
    background-color: $SURF_GRAY_2;
  }
  50%,
  100% {
    background-color: lighten($SURF_GRAY_2, 40);
  }
}

