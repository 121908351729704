@import '../colors.scss';
@import '../mixins.scss';

footer {
  border-top: 1px solid #F4F4F5;
  height: 72px;
  font-size: 0.925rem;

  .container-fluid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 70px;

    @media (max-width: 499px) {
      flex-direction: column;
    }
  }
  
  @media (max-width: 499px) {
    height: auto;
  }

  .copyright {
    color: $SURF_GRAY;
    opacity: .4;
    @media (max-width: 500px) {
      text-align: center;
    }
  }
  .nav {
    margin-top: 0;
    margin-bottom: 0;

    .nav-item {
      margin-right: 15px;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 20px;

      .nav-item {
        margin-right: 15px;
        height: 40px;
        text-align: center;
      }
    }
  }
  .dod {
    color: $SURF_GRAY;
    opacity: .4;

    &:hover {
      opacity: 1;
      color: darken($SURF_BLUE, 10%);
    }
  }
}