.index-page {
  background-color: $SURF_GRAY_BACKGROUND;

  main {
    
    #dashboard {
      min-height1: 100%;
      display1: flex;

      & > .container-fluid {
        min-height1: 100%;
        display1: flex;
        flex-grow1: 1;

        & > .row {
          flex-grow1: 1;

          & > [class^=col], & > [class^=order] {
            display1: flex;
            
            .panel {
              flex-grow: 1;
              min-height: 300px;
              background: #FFFFFF;
              box-shadow: 0px -1px 1px rgba(32, 38, 42, 0.01), 0px 2px 2px rgba(134, 135, 160, 0.05), 0px 4px 4px rgba(134, 135, 160, 0.05), 0px 8px 8px rgba(134, 135, 160, 0.05);
              border-radius: 1rem;
              text-align: left;
              padding: 1rem;

              .header {
                padding-top: .75rem;
                padding-bottom: 1.5rem;
                height: 76px;

                &.header-toggle {
                  display: flex;
                  justify-content: center;
                }
                
                h4 {
                  font-weight: 600;
                  font-size: 1.4rem;
                }

                & > .nav {
                  height: 32px;
                  border-radius: 12px;
                  padding: 2px;
                  background-color: $SURF_GRAY_BACKGROUND;

                  .nav-item {

                    a.nav-link {
                      min-width: 100px;
                      text-align: center;
                      height: 28px;
                      border-radius: 10px;
                      font-size: 0.8125rem;
                      font-weight: 500;
                      padding: .25rem 1rem;
                      cursor: pointer;

                      @media (max-width: 575px) {
                        min-width: 70px;
                      }
                      @media (max-width: 350px) {
                        min-width: auto;
                        padding: .25rem 0.75rem;
                      }

                      &.active {
                        background-color: white;
                        color: $SURF_BLACK;
                      }
                    }
                  }
                }
              }

              &.panel-main {
                @media (max-width: 1199px) {
                  margin-bottom: 16px;
                }
              }

              &.panel-orderbook {
              }

              &.panel-exchange {
                @media (max-width: 767px) {
                  margin-bottom: 16px;
                }}
              
              .subheader {
                margin-bottom: 10px;

                dl {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  margin: 0;

                  dd, dt {
                    margin: 0;
                    font-size: 0.85rem;
                  }
                  dd {
                    color: $SURF_SECONDARY;
                  }
                }
              }
              form {
                display: flex;
                flex-direction: column;
              
               .form-group {
                 flex-grow: 1;
               }
               button.btn-primary {
                 margin-top: .5rem;
                 font-size: 1rem;
                 font-weight: 600;
                 border-radius: .5rem;
                 padding: .5rem 0;
                 height: 3rem;
               }
               .form-group {
                 border-bottom: 1px solid #DFDFE7;
              
                 label {
                  font-size: 13px;
                  color: $SURF_TERTIARY;
                  position: relative;
                  top: 5px;
              
                  .invalid-feedback {
                    font-size: 13px;
                    color: #DF3E6F;
                  }
                 }
              
                  input, .form-control  {
                    &, &:active, &:focus {
                      height: 3rem;
                      font-size: 1.0625rem;
                      font-weight: 400;
                      border: none;
                      background-color: transparent;
                      box-shadow: none;
                      outline: none;
                    }
                    &::placeholder {
                      color: rgba(black, 0.3);
                      font-size: 16px;
                      font-weight: 400;
                      letter-spacing: -0.02em;
                      text-overflow: ellipsis;
                      opacity: 1;
                    }
                    &::-webkit-input-placeholder {
                      color: rgba(black, 0.3);
                      font-size: 16px;
                      font-weight: 400;
                      letter-spacing: -0.02em;
                      text-overflow: ellipsis;
                      opacity: 1;
                    }
                    &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
                      color: rgba(black, 0.3);
                      font-size: 16px;
                      font-weight: 400;
                      letter-spacing: -0.02em;
                      text-overflow: ellipsis;
                      opacity: 1;
                    }
                    &:-ms-input-placeholder {
                      color: rgba(black, 0.3);
                      font-size: 16px;
                      font-weight: 400;
                      letter-spacing: -0.02em;
                      text-overflow: ellipsis;
                      opacity: 1;
                    }
                  }
              
                  .input-group {
                    & > div {
                      padding: 0;
                      flex-basis: auto;
                      flex-shrink: 0;
                      flex-grow: 0;
              
                      & > div {
                        border: none;
                        background-color: transparent;
                        box-shadow: none;
                        outline: none;
              
                        *[class*=indicatorSeparator] {
                          visibility: hidden;
                        }
                        &[class*=menu] {
                          background: #FFFFFF;
                          box-shadow: 0px -1px 1px rgba(32, 38, 42, 0.05), 0px 2px 2px rgba(134, 135, 160, 0.05), 0px 4px 4px rgba(134, 135, 160, 0.05), 0px 8px 8px rgba(134, 135, 160, 0.05), 0px 16px 16px rgba(134, 135, 160, 0.1), 0px 48px 48px rgba(134, 135, 160, 0.1);
                          border-radius: 10px;
              
                          [class*=MenuList] {
                            padding: 0;
                            border-radius: 10px;
                            overflow: hidden;
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.selected-buy {
                .header {
                  & > .nav {
                    background-color: rgba($SURF_GREEN, .12);

                    .nav-item a.active {
                      &, &:hover, &:focus {
                        background-color: $SURF_GREEN;
                        color: white;
                        outline: none;
                      }
                    }
                  }
                }
                form {

                  button.btn-primary {
                    background-color: $SURF_GREEN;
                    border-color: $SURF_GREEN;
                
                    &:hover, &:focus {
                      &:active, &:focus {
                        background-color: desaturate(darken($SURF_GREEN, 5), 10);
                        border-color: desaturate(darken($SURF_GREEN, 5), 10);
                        box-shadow: 0 0 0 0.2rem rgba($SURF_GREEN, 0.3);
                      }
                    }
                  }
                }
              }
              &.selected-sell {
                .header {
                  & > .nav {
                    background-color: rgba($SURF_RED, .12);

                    .nav-item a.active {
                      &, &:hover, &:focus {
                        background-color: $SURF_RED;
                        color: white;
                        outline: none;
                      }
                    }
                  }
                }

                button.btn-primary {
                  background-color: $SURF_RED;
                  border-color: $SURF_RED;
              
                  &:hover, &:focus {
                    &:active, &:focus {
                      background-color: desaturate(darken($SURF_RED, 5), 10);
                      border-color: desaturate(darken($SURF_RED, 5), 10);
                      box-shadow: 0 0 0 0.2rem rgba($SURF_RED, 0.3);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.orderbook, .trades {
  margin-top: 23px;

  .orderbook__row, .trades__row {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .75rem 0;
    font-size: 1.0625rem;
    @include transition(0.15s);
    border-radius: 10px;
    background: transparent;

    .price, .volume {
      @include transition(0.15s);
    }

    &.no-data {
      .price, .volume {

        &:after {
          content: '';
          height: 16px;
          width: 50%;
          max-width: 200px;
          min-width: 120px;
          border-radius: 6px;
          display: block;

          @include background-gradient($SURF_GRADIENT_BACKGROUND, $SURF_GRADIENT_BACKGROUND_WAVE);
          animation: shine-lines 2.5s infinite linear;
          opacity: 0.6;
        }
      }
      .volume:after {
        opacity: 0.2;
        animation: shine-lines 2.5s infinite linear;
        animation-delay1: 0.4s;
      }
    }
    .volume {
      color: $SURF_TERTIARY;
    }
    
    .graph {
      position: absolute;
      right: 8px;
      top: 0;
      height: 100%;
      min-width: 3px;

      &.graph__negative {
        background-color: rgba($SURF_RED, .12);
      }

      &.graph__positive {
        background-color: rgba($SURF_GREEN, .12);
      }
    }
  }

  .orderbook__row {
    &:not(.no-data):hover {
      cursor: pointer;
      @include transition(0.3s);
      background: linear-gradient(90deg, $SURF_GRAY_BACKGROUND, white);
      overflow: hidden;

      .price {
        transform: translateX(10px);
        @include transition(0.3s);
      }
    }
  }
}


@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  
  40%, 100% {
    background-position: 200px;
  }
}

#chart-tabs-tabpane-chart, #chart-tabs-tabpane-depth, #chart-tabs-tabpane-price, #chart-tabs-tabpane-tvl {
  margin-left: -1rem;
  margin-right: -1rem;
  min-height: 400px;
  height: 450px;
  

  &.active {
    display: flex;
  }

  & > * {
    flex: 1;
    height: auto !important;
  }
}


  .details {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 575px) {
      flex-direction: column;
      justify-content: start;
    }
  }

  .details > .detail {
    padding-right: 15px;
    text-align: right;

    @media (max-width: 575px) {
      display: none;
    }
    .tooltip-value {
      width: auto;
    }
  }
  .details-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .detail {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
  }
  .tooltip-value {
    width: 50px;
    font-weight: 400;
  }
  .detail {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
  }
  .tooltip-label {
    font-size: 11px;
    color: black;
    margin-right: 5px;
  }